
/** to add hover effect on icon **/
.filter-hover {filter: invert(79%) sepia(60%) saturate(6121%) hue-rotate(20deg) brightness(89%) contrast(94%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(100%) sepia(19%) saturate(254%) hue-rotate(303deg) brightness(115%) contrast(100%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        line-height: 1;
        height: 100%;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            visibility: hidden;
            position: absolute;
            //background-color: $bg-color-nav-highlight;
            //mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            //     background: transparentize($color-black, 0.5);
            // }
            // // For Edge fallback
            // @supports (-ms-ime-align:auto) {
            //     background: transparentize($color-black, 0.5);
            // }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}
