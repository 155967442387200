// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
 @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/

section.section-01-accueil {
    background-color: $color-white;
    background-image: url(../images/accueil_section01_bg.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px 0;
    .section-01-logo {
        margin: auto;
    }

    div.txt {
        padding-left: 35px;
        h3 {
            color: $color-black;
            font-size: $font-size-36;
            line-height: 1.2;
        }
        p {
            font-weight: 400;
            font-family: $font-family-2;
            line-height: 1.1;
            font-size: $font-size-20;
        }
        h4 {
            color: $color-black;
            margin-top: auto;
            font-weight: 700;
            font-size: $font-size-26;
        }
        ul {
            list-style: none;
            li {
                display: flex;
                font-size: $font-size-20;
                line-height: 1.5;
                justify-content: space-between;
                span {
                    color: $color-3;
                }
            }
            &.check li {
                  position: relative;
                  text-transform: inherit;
                  line-height: 1.8;
                  font-size: $font-size-24;
                  &:before {
                        content: '';
                        height: 25px;
                        width: 25px;
                        position: absolute;
                        top: 8px;;
                        left: -50px;
                        //transform: translateY(-50%);
                        background-image: url(../images/le_spq_section04_check.jpg);
                        background-size: 100% 100%;
                }
            }
        }
    }

    div.image-actions {
        position: absolute;
        top: 15vw;
        right: 0;
        left: 60%;
        bottom: 0;
        transition: .3s;
        background-image: url(../images/nos_actions_section01_pho01.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media screen and (max-width: $size-lg-min) {
            top: 20vw;
            @media screen and (max-width: $size-xs-max) {
                width: 100%;
                height: 120vw;
                position: relative;
                top: inherit;
                right: inherit;
                left: inherit;
                bottom: inherit;
            }
        }

    }
}

section.parallax {
    padding: 150px 10px 130px 10px;
    background-image: url(../images/accueil_section02_parallax_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    div.txt {
        width: 100%;
        max-width: 1200px;
        h4 {
            color: $color-white;
            text-align: center;
            font-size: $font-size-36;
        }
    }
}

section.section-text-image {
    div.txt {
        background-image:url(../images/accueil_section04_bg.jpg);
        background-position: left bottom;
        background-size: cover;
        width: 55%;
        padding: 100px 5%;
    }
    div.img-side {
        background-image:url(../images/accueil_section04_pho01.jpg);
        background-position: left bottom;
        background-size: cover;
        width: 45%;
    }
    &.page-actions div.txt {
        width: 50%;
    }


    h3 {
        color: $color-white;
        font-size: $font-size-36;
        font-weight: 500;
    }
    h4 {
        color: $color-white;
        font-size: $font-size-24;
    }
    ul {
        list-style: none;
        padding-left: 50px;
        li {
            color: $color-white;
            line-height: 1.5;
            font-weight: 500;
            text-transform: inherit;
            font-size: $font-size-26;
            position: relative;
            &::before {
                content: '';
                height: 25px;
                width: 25px;
                position: absolute;
                top: 8px;;
                left: -50px;
                //transform: translateY(-50%);
                background-image: url(../images/accueil_section04_check.jpg);
                background-size: 100% 100%;
            }
        }
    }
    @media screen and (max-width: $size-sm-max){
        div.txt {
            width: 100%;
            padding: 30px 10px 50px 10px;
        }
        &.page-actions div.txt {
            width: 100%;
        }
        div.img-side {
            width: 100%;
            height: 400px;
        }
    }
}

section.section-liens-partenaires {
  padding: 20px;
    h3 {
        text-align: center;
        color: #333333;
        text-transform: inherit;
        font-size: $font-size-36;
    }
    div.item {
        padding: 10px;
        overflow: hidden;
        transition: .3s;
        img {
            min-width: 60px;
            transition: .3s;
        }
        &:hover {
          transition: .3s;
            img{
                transform: scale(1.1);
                transition: .3s;
            }
        }
    }
}

section.section-gallery-accueil {
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
        width: 25%;
        overflow: hidden;
        border-right: 2px solid $color-white;
        img {
            transition: .3s;
            transform: scale(1);
        }
        &:hover {
            img{
                transition: .3s;
                transform: scale(1.2);
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
      & > div {
          width: 50%;
        }
    }
}
/*******************************************************************************
* PAGE X
*/
.section-text-image-page {
    @extend .section-text-image;
    div.txt {
        padding: 100px 10%!important;
        background-image: none!important;
        background-color: $color-4;
    }
}

.section-white-text {
    padding: 50px 10%;
    div.text-wrapper {
        max-width: 1400px;
        margin: auto;
    }
    h3 {
        color: $color-black;
        font-size: $font-size-36;
        padding-bottom: 2rem;
    }
    ul {
      list-style: none;
      padding-left: 50px;
      li {
          color: $color-black;
          line-height: 1.5;
          font-weight: 500;
          text-transform: inherit;
          font-size: $font-size-26;
          padding-bottom: 2rem;
          position: relative;
          &::before {
              content: '';
              height: 25px;
              width: 25px;
              position: absolute;
              top: 8px;;
              left: -50px;
              //transform: translateY(-50%);
              background-image: url(../images/le_spq_section04_check.jpg);
              background-size: 100% 100%;
          }
      }
    }
}
div.img-frame {
    width: 100%;
    img{
        width: 100%;
    }
}

section.section-equipe-liste {
    background-color: $color-white;
    background-image: url(../images/accueil_section01_bg.jpg);
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 20px 0;
    h3 {
        color: $color-black;
        text-align: center;
        text-transform: inherit;
        font-size: $font-size-36;
    }

    div.fiche {
        margin: 20px 0;
        justify-content: space-between;
        div.portrait {
            flex: none;
        }
        & > div {
            max-width: 50%;
        }
        h4 {
          font-size: $font-size-24;
          line-height: 1.3;
          &.poste {
              color: $color-2;
          }
        }
        a {
          font-size: $font-size-24;
          line-height: 1.3;
          color: $color-black;
          &:hover {
              color: $color-2;
          }
        }
        p {
          color: $color-3;
          line-height: 1.3;
        }
        .pdf {
            margin-top: auto;
            margin-left: auto;
            transition: .5s;
            transform: scale(1);
            &:hover {
              transition: .5s;
                transform: scale(1.1);
            }
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {

        div.fiche {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            & > div {
                max-width: 100%;
            }
            h4, a {
                text-align: center;
                display: block;
            }
        }
    }
}

section.section-menu-filter {
    padding: 30px 10px;;
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        li {
            width: 33%;
            line-height: 1.5;
            text-transform: inherit;
            padding-right: 30px;
            a {
                font-weight: 700;
                color: #333;
                text-decoration: underline!important;
                font-size: $font-size-24;
                &:hover {
                    color: $color-3;
                }
            }
            position: relative;
            &::before {
                content: '';
                height: 25px;
                width: 25px;
                position: absolute;
                top: 8px;;
                left: -50px;
                //transform: translateY(-50%);
                background-image: url(../images/le_spq_section04_check.jpg);
                background-size: 100% 100%;
            }
        }
    }
    @media screen and (max-width: $size-sm-max){
      ul {
          li {
              width: 50%;
            }
        }
        @media screen and (max-width: 650px){
          ul {
              justify-content: center;
              li {
                    width: auto;
                    margin: 15px 10px;
                    &::before {
                        top: 0;
                        left: -30px;
                    }
                }
            }
        }
    }
}



section.gallerie-products {
    padding: 30px 0 150px 0;
    background-image: url('../images/territoire_section02_bg.jpg');
    background-position: center bottom;
    background-size: cover;
    min-height: 400px;
    background-repeat: no-repeat;
    h3 {
      color: $color-white;
      font-size: $font-size-26;
      text-transform: inherit;
      float: left;
      padding-right: 20px;
    }
    h4 {
        color: $color-3;
        font-size: $font-size-26;
        text-transform: inherit;
    }

    a.siteLink {
      float: left;
        div.img {
            position: relative;
            div.overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0;
              visibility: hidden;
              transition: .5s;
            }
        }
        &:hover {
          div.img {
              position: relative;
              div.overlay {
                transition: .5s;
                opacity: 1;
                visibility: visible;
              }
          }
        }
    }
}


section.section-coordonnees {
      background-color: $color-white;
      background-image: url(../images/accueil_section01_bg.jpg);
      background-position: center top;
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding: 20px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        transition: .3s;
        padding: 0;
        margin: 0;
        & > div {
            width: 50%;
            transition: .3s;
        }
    }

    div.left-side {
        .content-box {
            padding-left: 20%;
        }
    }
    div.right-side {
        .content-box {
            padding-right: 20%;
        }
    }

    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            transition: .3s;
            & > div {
                width: 100%;
                transition: .3s;
            }
        }
        div.left-side, div.right-side {
            .content-box {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

    }

    h3 {
        color: $color-black;
        text-align: center;
        text-transform: inherit;
        font-size: $font-size-36;
    }
    h4 {
        font-size: $font-size-28;
        line-height: 1.5;
        font-weight: bold;
    }
    div.icon {
        width: 60px;
    }
    p {
          line-height: 1.3;
          font-weight: 600;
          //font-size: $font-size-28;
          span.jour {
              width: 130px;
          }
      }
    a.contact-link {
        font-size: $font-size-28;
        line-height: 1.5;
        color: #333;
        font-weight: bold;
        &:hover, &:focus {
            color: $color-2;
        }
    }
}
.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
