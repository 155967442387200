.btn {
    background-color: #cc5e28;
    color: $color-white;
    //border: 3px solid lighten($color-primary, 20%);
    border-radius: 0;
    padding: 2px 15px;
    font-size: $font-size-20;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &.disabled{
      opacity: 1;
    }
    &:hover {
        transition: all 0.2s ease-in;
        background-color: lighten(#cc5e28, 20%);
        color: $color-black;
        //border: 3px solid darken($color-primary, 40%);
    }
}
